import React from 'react'
import { Link, graphql } from 'gatsby'
import get from 'lodash/get'
import Helmet from 'react-helmet'
import { Grid, Row, Col } from 'react-flexbox-grid'

import Layout from '../../components/Layout'
import LightboxImage from '../../components/LightboxImage'
import expoValenciennes from '../../data/expo-valenciennes'
import expoDouai from '../../data/expo-douai'
import expoCharleville from '../../data/expo-charleville'
import './css.css'

class portfolioIndex extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title');
    const siteDescription = get(this,'props.data.site.siteMetadata.description');

    return (
      <Layout>
        <Helmet
          htmlAttributes={{ lang: 'fr' }}
          meta={[{ name: 'description', content: siteDescription }]}
          title={`${siteTitle} | Galeries`}
        />
        <h2>Galeries par expositions</h2>
          <br />
          <h3 className={'expoTitle'}>Exposition de Charleville-Mézières - Octobre 2021</h3>
          <br />
          <div
              style={{
                  textAlign: 'center',
              }}
          >
              <Grid fluid>
                  <Row>
                      {expoCharleville.map(p => (
                          <Col xs={12} sm={12} md={6} lg={4} key={p.title}>
                              <div className={'galerieItem'}
                                   style={{
                                       display: 'flex',
                                       flexDirection: 'column',
                                       alignItems: 'center',
                                   }}
                              >

                                  <LightboxImage
                                      src={p.image}
                                      author={p.author}
                                      title={p.title}
                                      mini={p.miniature}
                                  />
                                  <p>{p.author}</p>
                              </div>
                              {/*</Link>*/}
                          </Col>
                      ))}
                  </Row>
              </Grid>
          </div>
          <br />
          <h3 className={'expoTitle'}>Exposition de Douai - Septembre 2019</h3>
          <br />
          <div
              style={{
                  textAlign: 'center',
              }}
          >
              <Grid fluid>
                  <Row>
                      {expoDouai.map(p => (
                          <Col xs={12} sm={12} md={6} lg={4} key={p.title}>
                              <div className={'galerieItem'}
                                   style={{
                                       display: 'flex',
                                       flexDirection: 'column',
                                       alignItems: 'center',
                                   }}
                              >

                                  <LightboxImage
                                      src={p.image}
                                      author={p.author}
                                      title={p.title}
                                      mini={p.miniature}
                                  />
                                  <p>{p.author}</p>
                              </div>
                              {/*</Link>*/}
                          </Col>
                      ))}
                  </Row>
              </Grid>
          </div>
        <br />
        <h3 className={'expoTitle'}>Exposition de Valenciennes - Mai 2019</h3>
        <br />
        <div
          style={{
            textAlign: 'center',
          }}
        >
          <Grid fluid>
            <Row>
              {expoValenciennes.map(p => (
                <Col xs={12} sm={12} md={6} lg={4} key={p.title}>
                  {/*<Link to={`/galeries/view`} state={p} key={p.title} expo={expoValenciennes}>*/}
                    <div className={'galerieItem'}
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                      }}
                    >
                      {/*<div*/}
                      {/*  style={{*/}
                      {/*    width: 250,*/}
                      {/*    height: 200,*/}
                      {/*    margin: 10,*/}
                      {/*    background: `url(${p.miniature})`,*/}
                      {/*    backgroundSize: 'cover',*/}
                      {/*    backgroundPosition: 'center center',*/}
                      {/*  }}*/}
                      {/*/>*/}

                        <LightboxImage
                            src={p.image}
                            author={p.author}
                            title={p.title}
                            mini={p.miniature}
                        />
                      <p>{p.author}</p>
                    </div>
                  {/*</Link>*/}
                </Col>
              ))}
            </Row>
          </Grid>
        </div>
      </Layout>
    )
  }
}

export default portfolioIndex

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`
