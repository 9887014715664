import bakir1 from './../assets/images/douai/bakir1.jpg'
import bakirMini1 from './../assets/images/douai/bakir1-1.jpg'
import bakir2 from './../assets/images/douai/bakir2.jpg'
import bakirMini2 from './../assets/images/douai/bakir2-1.jpg'
import bakir3 from './../assets/images/douai/bakir3.jpg'
import bakirMini3 from './../assets/images/douai/bakir3-1.jpg'
import bakir4 from './../assets/images/douai/bakir4.jpg'
import bakirMini4 from './../assets/images/douai/bakir4-1.jpg'
import beugnies1 from './../assets/images/douai/beugnies1.jpg'
import beugniesMini1 from './../assets/images/douai/beugnies1-1.jpg'
import beugnies2 from './../assets/images/douai/beugnies2.jpg'
import beugniesMini2 from './../assets/images/douai/beugnies2-1.jpg'
import beugnies3 from './../assets/images/douai/beugnies3.jpg'
import beugniesMini3 from './../assets/images/douai/beugnies3-1.jpg'
import beugnies4 from './../assets/images/douai/beugnies4.jpg'
import beugniesMini4 from './../assets/images/douai/beugnies4-1.jpg'
import dhetz1 from './../assets/images/douai/dhetz1.jpg'
import dhetzMini1 from './../assets/images/douai/dhetz1-1.jpg'
import dhetz2 from './../assets/images/douai/dhetz2.jpg'
import dhetzMini2 from './../assets/images/douai/dhetz2-1.jpg'
import dhetz3 from './../assets/images/douai/dhetz3.jpg'
import dhetzMini3 from './../assets/images/douai/dhetz3-1.jpg'
import dhetz4 from './../assets/images/douai/dhetz4.jpg'
import dhetzMini4 from './../assets/images/douai/dhetz4-1.jpg'
import dumortier1 from './../assets/images/douai/dumortier1.jpg'
import dumortierMini1 from './../assets/images/douai/dumortier1-1.jpg'
import dumortier2 from './../assets/images/douai/dumortier2.jpg'
import dumortierMini2 from './../assets/images/douai/dumortier2-1.jpg'
import dumortier3 from './../assets/images/douai/dumortier3.jpg'
import dumortierMini3 from './../assets/images/douai/dumortier3-1.jpg'
import dumortier4 from './../assets/images/douai/dumortier4.jpg'
import dumortierMini4 from './../assets/images/douai/dumortier4-1.jpg'
import gerard1 from './../assets/images/douai/gerard1.jpg'
import gerardMini1 from './../assets/images/douai/gerard1-1.jpg'
import gerard2 from './../assets/images/douai/gerard2.jpg'
import gerardMini2 from './../assets/images/douai/gerard2-1.jpg'
import gerard3 from './../assets/images/douai/gerard3.jpg'
import gerardMini3 from './../assets/images/douai/gerard3-1.jpg'
import gerard4 from './../assets/images/douai/gerard4.jpg'
import gerardMini4 from './../assets/images/douai/gerard4-1.jpg'
import gerin1 from './../assets/images/douai/gerin1.jpg'
import gerinMini1 from './../assets/images/douai/gerin1-1.jpg'
import gerin2 from './../assets/images/douai/gerin2.jpg'
import gerinMini2 from './../assets/images/douai/gerin2-1.jpg'
import gerin3 from './../assets/images/douai/gerin3.jpg'
import gerinMini3 from './../assets/images/douai/gerin3-1.jpg'
import gerin4 from './../assets/images/douai/gerin4.jpg'
import gerinMini4 from './../assets/images/douai/gerin4-1.jpg'
import gobert1 from './../assets/images/douai/gobert1.jpg'
import gobertMini1 from './../assets/images/douai/gobert1-1.jpg'
import gobert2 from './../assets/images/douai/gobert2.jpg'
import gobertMini2 from './../assets/images/douai/gobert2-1.jpg'
import gobert3 from './../assets/images/douai/gobert3.jpg'
import gobertMini3 from './../assets/images/douai/gobert3-1.jpg'
import gobert4 from './../assets/images/douai/gobert4.jpg'
import gobertMini4 from './../assets/images/douai/gobert4-1.jpg'
import jung1 from './../assets/images/douai/jung1.jpg'
import jungMini1 from './../assets/images/douai/jung1-1.jpg'
import jung2 from './../assets/images/douai/jung2.jpg'
import jungMini2 from './../assets/images/douai/jung2-1.jpg'
import jung3 from './../assets/images/douai/jung3.jpg'
import jungMini3 from './../assets/images/douai/jung3-1.jpg'
import jung4 from './../assets/images/douai/jung4.jpg'
import jungMini4 from './../assets/images/douai/jung4-1.jpg'
import marque1 from './../assets/images/douai/marque1.jpg'
import marqueMini1 from './../assets/images/douai/marque1-1.jpg'
import marque2 from './../assets/images/douai/marque2.jpg'
import marqueMini2 from './../assets/images/douai/marque2-1.jpg'
import marque3 from './../assets/images/douai/marque3.jpg'
import marqueMini3 from './../assets/images/douai/marque3-1.jpg'
import marque4 from './../assets/images/douai/marque4.jpg'
import marqueMini4 from './../assets/images/douai/marque4-1.jpg'
import ponthieu1 from './../assets/images/douai/ponthieu1.jpg'
import ponthieuMini1 from './../assets/images/douai/ponthieu1-1.jpg'
import ponthieu2 from './../assets/images/douai/ponthieu2.jpg'
import ponthieuMini2 from './../assets/images/douai/ponthieu2-1.jpg'
import ponthieu3 from './../assets/images/douai/ponthieu3.jpg'
import ponthieuMini3 from './../assets/images/douai/ponthieu3-1.jpg'
import ponthieu4 from './../assets/images/douai/ponthieu4.jpg'
import ponthieuMini4 from './../assets/images/douai/ponthieu4-1.jpg'

const data = [
    {
        author: 'BAKIR Emine',
        title: 'Colonne - Huile sur bois - 30 x 30 cm',
        description: ``,
        image: bakir1,
        miniature: bakirMini1,
        position: 1
    },
    {
        author: 'BAKIR Emine',
        title: 'Colonne - Huile sur bois - 30 x 30 cm',
        description: ``,
        image: bakir2,
        miniature: bakirMini2,
        position: 2
    },
    {
        author: 'BAKIR Emine',
        title: 'Colonne - Huile sur bois - 30 x 30 cm',
        description: ``,
        image: bakir3,
        miniature: bakirMini3,
        position: 3
    },
    {
        author: 'BAKIR Emine',
        title: 'Colonne - Huile sur bois - 30 x 30 cm',
        description: ``,
        image: bakir4,
        miniature: bakirMini4,
        position: 4
    },
    {
        author: 'BEUGNIES Jean-Paul',
        title: 'Padaung - Aquarelle - 50 x 70 cm',
        description: ``,
        image: beugnies1,
        miniature: beugniesMini1,
        position: 5
    },
    {
        author: 'BEUGNIES Jean-Paul',
        title: 'Asiatique - Aquarelle - 50 x 70 cm',
        description: ``,
        image: beugnies2,
        miniature: beugniesMini2,
        position: 6
    },
    {
        author: 'BEUGNIES Jean-Paul',
        title: 'Asiatique - Aquarelle - 50 x 70 cm',
        description: ``,
        image: beugnies3,
        miniature: beugniesMini3,
        position: 7
    },
    {
        author: 'BEUGNIES Jean-Paul',
        title: 'Asiatique - Aquarelle - 50 x 70 cm',
        description: ``,
        image: beugnies4,
        miniature: beugniesMini4,
        position: 8
    },
    {
        author: 'DHETZ Jacqueline',
        title: 'Etalon - Huile sur toile - 60 x 60 cm (détail)',
        description: ``,
        image: dhetz1,
        miniature: dhetzMini1,
        position: 9
    },
    {
        author: 'DHETZ Jacqueline',
        title: 'Promenade - Huile sur toile - 60 x 60 cm',
        description: ``,
        image: dhetz2,
        miniature: dhetzMini2,
        position: 10
    },
    {
        author: 'DHETZ Jacqueline',
        title: 'Amour - Huile sur toile - 60 x 60 cm',
        description: ``,
        image: dhetz3,
        miniature: dhetzMini3,
        position: 11
    },
    {
        author: 'DHETZ Jacqueline',
        title: 'Bucéphale - Huile sur toile - 60 x 60 cm',
        description: ``,
        image: dhetz4,
        miniature: dhetzMini4,
        position: 12
    },
    {
        author: 'DUMORTIER Virginie',
        title: 'Portrait - Acrylique sur toile - 60 x 80 cm',
        description: ``,
        image: dumortier1,
        miniature: dumortierMini1,
        position: 13
    },
    {
        author: 'DUMORTIER Virginie',
        title: 'Portrait - Acrylique sur toile - 60 x 80 cm',
        description: ``,
        image: dumortier2,
        miniature: dumortierMini2,
        position: 14
    },
    {
        author: 'DUMORTIER Virginie',
        title: 'Portrait - Acrylique sur toile - 80 x 80 cm',
        description: ``,
        image: dumortier3,
        miniature: dumortierMini3,
        position: 15
    },
    {
        author: 'DUMORTIER Virginie',
        title: 'Portrait - Acrylique sur toile - 80 x 80 cm',
        description: ``,
        image: dumortier4,
        miniature: dumortierMini4,
        position: 16
    },
    {
        author: 'GERARD Alain',
        title: 'Spectacle équestre andalou - Aquarelle - 45 x 60 cm',
        description: ``,
        image: gerard1,
        miniature: gerardMini1,
        position: 17
    },
    {
        author: 'GERARD Alain',
        title: 'Spectacle équestre andalou - Aquarelle - 45 x 60 cm',
        description: ``,
        image: gerard2,
        miniature: gerardMini2,
        position: 18
    },
    {
        author: 'GERARD Alain',
        title: 'Cheval arabe - Aquarelle - 40 x 50 cm',
        description: ``,
        image: gerard3,
        miniature: gerardMini3,
        position: 19
    },
    {
        author: 'GERARD Alain',
        title: 'Etude - Aquarelle & crayon - 50 x 40 cm',
        description: ``,
        image: gerard4,
        miniature: gerardMini4,
        position: 20
    },
    {
        author: 'GERIN Severin',
        title: 'Atelier du modelage Bruxelles - Huile sur toile - 50 x 100 cm (détail)',
        description: ``,
        image: gerin1,
        miniature: gerinMini1,
        position: 21
    },
    {
        author: 'GERIN Severin',
        title: 'Atelier du modelage Bruxelles - Huile sur toile - 50 x 100 cm',
        description: ``,
        image: gerin2,
        miniature: gerinMini2,
        position: 22
    },
    {
        author: 'GERIN Severin',
        title: 'Atelier du modelage Bruxelles - Huile sur toile - 90 x 100 cm',
        description: ``,
        image: gerin3,
        miniature: gerinMini3,
        position: 23
    },
    {
        author: 'GERIN Severin',
        title: 'Atelier du modelage Bruxelles - Huile sur toile - 100 x 100 cm',
        description: ``,
        image: gerin4,
        miniature: gerinMini4,
        position: 24
    },
    {
        author: 'GOBERT André',
        title: 'Marie - Techniques mixtes - 15 x 31 cm (sur socle)',
        description: ``,
        image: gobert1,
        miniature: gobertMini1,
        position: 25
    },
    {
        author: 'GOBERT André',
        title: 'Jean - Techniques mixtes - 30 x 40 cm',
        description: ``,
        image: gobert2,
        miniature: gobertMini2,
        position: 26
    },
    {
        author: 'GOBERT André',
        title: 'Agnès - Techniques mixtes - 30 x 40 cm',
        description: ``,
        image: gobert3,
        miniature: gobertMini3,
        position: 27
    },
    {
        author: 'GOBERT André',
        title: 'Louis - Techniques mixtes - 30 x 40 cm',
        description: ``,
        image: gobert4,
        miniature: gobertMini4,
        position: 28
    },
    {
        author: 'JUNG Dominique',
        title: 'Rayon de Lune - Huile sur toile - 80 x 80 cm (détail)',
        description: ``,
        image: jung1,
        miniature: jungMini1,
        position: 29
    },
    {
        author: 'JUNG Dominique',
        title: 'Mer de feu - Huile sur toile - 80 x 80 cm',
        description: ``,
        image: jung2,
        miniature: jungMini2,
        position: 30
    },
    {
        author: 'JUNG Dominique',
        title: 'Storm 1 - Huile sur toile - 80 x 80 cm',
        description: ``,
        image: jung3,
        miniature: jungMini3,
        position: 31
    },
    {
        author: 'JUNG Dominique',
        title: 'Storm 2 - Huile sur toile - 80 x 80 cm',
        description: ``,
        image: jung4,
        miniature: jungMini4,
        position: 32
    },
    {
        author: 'MARQUE Denis',
        title: 'Portrait - Acrylique sur bois - 40 x 60 cm',
        description: ``,
        image: marque1,
        miniature: marqueMini1,
        position: 33
    },
    {
        author: 'MARQUE Denis',
        title: 'Portrait - Acrylique sur bois - 40 x 60 cm',
        description: ``,
        image: marque2,
        miniature: marqueMini2,
        position: 34
    },
    {
        author: 'MARQUE Denis',
        title: 'Portrait - Acrylique sur bois - 40 x 60 cm',
        description: ``,
        image: marque3,
        miniature: marqueMini3,
        position: 35
    },
    {
        author: 'MARQUE Denis',
        title: 'Portrait - Acrylique sur bois - 40 x 60 cm',
        description: ``,
        image: marque4,
        miniature: marqueMini4,
        position: 36
    },
    {
        author: 'PONTHIEU Marie-Christine',
        title: 'Etude Mont Saint-Michel - Techniques mixtes - 80 x 100 cm',
        description: ``,
        image: ponthieu1,
        miniature: ponthieuMini1,
        position: 37
    },
    {
        author: 'PONTHIEU Marie-Christine',
        title: 'Etude Mont Saint-Michel - Huile sur toile - 80 x 100 cm',
        description: ``,
        image: ponthieu2,
        miniature: ponthieuMini2,
        position: 38
    },
    {
        author: 'PONTHIEU Marie-Christine',
        title: 'Etude Mont Saint-Michel - Huile sur toile - 80 x 100 cm',
        description: ``,
        image: ponthieu3,
        miniature: ponthieuMini3,
        position: 39
    },
    {
        author: 'PONTHIEU Marie-Christine',
        title: 'Etude Mont Saint-Michel - Huile sur toile - 80 x 100 cm',
        description: ``,
        image: ponthieu4,
        miniature: ponthieuMini4,
        position: 40
    },
];

export default data