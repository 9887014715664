import AndreGobert from './../assets/images/valenciennes/AndréGobert.jpg'
import BakirEmine from './../assets/images/valenciennes/BakirEmine.jpg'
import BeugniesJeanPaul from './../assets/images/valenciennes/BeugniesJeanPaul.jpg'
import BlondiauFrance from './../assets/images/valenciennes/BlondiauFrance.jpg'
import BonfittoAntoinette from './../assets/images/valenciennes/BonfittoAntoinette.jpg'
import BuffartStephane from './../assets/images/valenciennes/BuffartStéphane.jpg'
import CauchiesLouis from './../assets/images/valenciennes/CauchiesLouis.jpg'
import CazacuJulia from './../assets/images/valenciennes/CazacuJulia.jpg'
import CiavarellaMarc from './../assets/images/valenciennes/CiavarellaMarc.jpg'
import DelvauxAnnie from './../assets/images/valenciennes/DelvauxAnnie.jpg'
import DehtzJacqueline from './../assets/images/valenciennes/DehtzJacqueline.jpg'
import DumortierVirginie from './../assets/images/valenciennes/DumortierVirginie.jpg'
import EvrardMarcelle from './../assets/images/valenciennes/EvrardMarcelle.jpg'
import FievezBeatrice from './../assets/images/valenciennes/FievezBéatrice.jpg'
import FouquembergDominique from './../assets/images/valenciennes/FouquembergDominique.jpg'
import GerardAlain from './../assets/images/valenciennes/GérardAlain.jpg'
import GerinSeverin from './../assets/images/valenciennes/GérinSéverin.jpg'
import HenquinMarieChristine from './../assets/images/valenciennes/HenquinMarieChristine.jpg'
import HonnayTherese from './../assets/images/valenciennes/HonnayThérèse.jpg'
import JungDominique from './../assets/images/valenciennes/JungDominique.jpg'
import KoussipetkosGeorgios from './../assets/images/valenciennes/KoussipetkosGeorgios.jpg'
import LanzoneLaura from './../assets/images/valenciennes/LanzoneLaura.jpg'
import LeclercqAgnes from './../assets/images/valenciennes/LeclercqAgnès.jpg'
import LimeletteJeanFrancois from './../assets/images/valenciennes/LimeletteJeanFrançois.jpg'
import MarqueDenis from './../assets/images/valenciennes/MarqueDenis.jpg'
import MingrinoAttilio from './../assets/images/valenciennes/MingrinoAttilio.jpg'
import MrowczynskiPierre from './../assets/images/valenciennes/MrowczynskiPierre.jpg'
import NoelDinah from './../assets/images/valenciennes/NoelDinah.jpg'
import PonthieuMarieChristine from './../assets/images/valenciennes/PonthieuMarieChristine.jpg'
import RinconGilMariPili from './../assets/images/valenciennes/RinconGilMariPili.jpg'
import RossiAnita from './../assets/images/valenciennes/RossiAnita.jpg'
import TellierJacqueline from './../assets/images/valenciennes/TellierJacqueline.jpg'

import AndreGobertMini from './../assets/images/valenciennes/AndréGobert-1.jpg'
import BakirEmineMini from './../assets/images/valenciennes/BakirEmine-1.jpg'
import BeugniesJeanPaulMini from './../assets/images/valenciennes/BeugniesJeanPaul-1.jpg'
import BlondiauFranceMini from './../assets/images/valenciennes/BlondiauFrance-1.jpg'
import BonfittoAntoinetteMini from './../assets/images/valenciennes/BonfittoAntoinette-1.jpg'
import BuffartStephaneMini from './../assets/images/valenciennes/BuffartStéphane-1.jpg'
import CauchiesLouisMini from './../assets/images/valenciennes/CauchiesLouis-1.jpg'
import CazacuJuliaMini from './../assets/images/valenciennes/CazacuJulia-1.jpg'
import CiavarellaMarcMini from './../assets/images/valenciennes/CiavarellaMarc-1.jpg'
import DelvauxAnnieMini from './../assets/images/valenciennes/DelvauxAnnie-1.jpg'
import DehtzJacquelineMini from './../assets/images/valenciennes/DehtzJacqueline-1.jpg'
import DumortierVirginieMini from './../assets/images/valenciennes/DumortierVirginie-1.jpg'
import EvrardMarcelleMini from './../assets/images/valenciennes/EvrardMarcelle-1.jpg'
import FievezBeatriceMini from './../assets/images/valenciennes/FievezBéatrice-1.jpg'
import FouquembergDominiqueMini from './../assets/images/valenciennes/FouquembergDominique-1.jpg'
import GerardAlainMini from './../assets/images/valenciennes/GérardAlain-1.jpg'
import GerinSeverinMini from './../assets/images/valenciennes/GérinSéverin-1.jpg'
import HenquinMarieChristineMini from './../assets/images/valenciennes/HenquinMarieChristine-1.jpg'
import HonnayThereseMini from './../assets/images/valenciennes/HonnayThérèse-1.jpg'
import JungDominiqueMini from './../assets/images/valenciennes/JungDominique-1.jpg'
import KoussipetkosGeorgiosMini from './../assets/images/valenciennes/KoussipetkosGeorgios-1.jpg'
import LanzoneLauraMini from './../assets/images/valenciennes/LanzoneLaura-1.jpg'
import LeclercqAgnesMini from './../assets/images/valenciennes/LeclercqAgnès-1.jpg'
import LimeletteJeanFrancoisMini from './../assets/images/valenciennes/LimeletteJeanFrançois-1.jpg'
import MarqueDenisMini from './../assets/images/valenciennes/MarqueDenis-1.jpg'
import MingrinoAttilioMini from './../assets/images/valenciennes/MingrinoAttilio-1.jpg'
import MrowczynskiPierreMini from './../assets/images/valenciennes/MrowczynskiPierre-1.jpg'
import NoelDinahMini from './../assets/images/valenciennes/NoelDinah-1.jpg'
import PonthieuMarieChristineMini from './../assets/images/valenciennes/PonthieuMarieChristine-1.jpg'
import RinconGilMariPiliMini from './../assets/images/valenciennes/RinconGilMariPili-1.jpg'
import RossiAnitaMini from './../assets/images/valenciennes/RossiAnita-1.jpg'
import TellierJacquelineMini from './../assets/images/valenciennes/TellierJacqueline-1.jpg'

const data = [
  {
    author: 'GOBERT André',
    title: 'Entreprises BAISE - Aquarelle - 40 x 50 cm',
    description: ``,
    image: AndreGobert,
    miniature: AndreGobertMini,
    position: 1
  },
  {
    author: 'BAKIR Emine',
    title: 'Oasis - Huile sur bois - 50 x 100 cm',
    description: `Son imagination n’a pas besoin d’être stimulée, elle trouve sujets et sources d’inspiration dans ses rêves, ses lectures et en ses racines. 
    <br><br>Observatrice redoutable, rien n’échappe à son regard scrutateur. Elle dissèque l’aspect insolite de la réalité qui la passionne et qu’elle désire reconstruire. 
    <br><br>Sa peinture existe, respire, la façon dont elle isole le sujet attire particulièrement l’attention sur l’originalité de sa composition. 
    Son œuvre a su saisir l’esprit d’un mode de vie, tout en évoquant des notes nouvelles suscitant une émotion véritable. 
    On peut sentir dans ses compositions un esprit toujours éveillé, une âme sensible.`,
    image: BakirEmine,
    miniature: BakirEmineMini,
    position: 2
  },
  {
    author: 'BEUGNIES Jean-Paul',
    title: 'Les Bergers - Aquarelle - 40 x 60 cm',
    description: `Technique toute en nuance, son œuvre restitue des émotions simples et pures, à travers une figuration imprégnée de douceur. 
    <br><br>D’autres sentiments, d’autres passions, se sont accoutumés à sa recherche personnelle de son univers et de ses valeurs. 
    <br><br>La tragédie d’un peuple et de ses racines multiples, c’est l’idée d’ethnies plurielles et cependant solidaires, de parcours au sein de paysages secrétant civilisation et culture. 
    De poétiques contrées et de ces ailleurs rêvés, intacts et fabuleux.`,
    image: BeugniesJeanPaul,
    miniature: BeugniesJeanPaulMini,
    position: 3
  },
  {
    author: 'BLONDIAU France ',
    title: 'Gare - Huile sur toile - 80 x 120 cm',
    description: `De manière singulièrement fréquente, l’œuvre peinte de cette artiste s’attache à représenter la modernité. 
    <br><br>Trains et gares acquièrent une signification centrale, représentée par de multiples variations aux perspectives assurées. 
    <br><br>Ses toiles confrontent l’Homme, représentant de l’ordre de la nature, à sa prétendue importance, devant l’immensité d’une ville tentaculaire. 
    <br><br>Cette série à l’exactitude des détails dépeint une atmosphère urbaine, facteur d’aliénation.`,
    image: BlondiauFrance,
    miniature: BlondiauFranceMini,
    position: 4
  },
  {
    author: 'BONFITTO Antoinette',
    title: 'Chasseur de miel - Techniques mixtes sur bois - 57 x 158 cm',
    description: `L'acceptation des aléas du travail spontané, une motivation sans faille et le refus de toute contrainte caractérisent une personnalité ancrée en elle-même. 
    <br><br>Son originalité brutale, s’explique par la quête de sa propre vérité et de son besoin de sonder son intériorité. 
    <br><br>Elle utilise comme seules matières le fusain sobrement rehaussé de pastel blanc, le noir étant le moyen d’atteindre l’expression totale.
    Son trait, élément de réflexion, pulsant de vie, soulignant la sensibilité est emprunt de sagesse et de vigueur sereine.`,
    image: BonfittoAntoinette,
    miniature: BonfittoAntoinetteMini,
    position: 5
  },
  {
    author: 'BUFFART Stéphane',
    title: 'La rosée - Huile sur toile - 80 x 80 cm',
    description: `Tout le monde sait que l’imagination est ce qui nous motive, mais quelles sont les motivations de cette imagination ? 
    <br><br>A travers ses tableaux, elle se pose cette question encore et encore. 
    Son imagination est concrète et diffuse en même temps, très poétique et toujours lumineuse. 
    <br><br>Sa recherche de la lumière, elle la trouve en sublimant la rosée, source de vie, de renaissance, tout en posant un regard lucide sur notre incapacité à respecter la nature.`,
    image: BuffartStephane,
    miniature: BuffartStephaneMini,
    position: 6
  },
  {
    author: 'CAUCHIES Louis',
    title: 'Château d\'Ecaussines - Acrylique sur toile - 100 x 100 cm',
    description: `Tel à un photographe, son œuvre monochrome est avant tout un travail épuré sur l’esthétique que dégagent les lieux chargés d’histoire. 
    <br><br>Paysagiste rêveur, énigmatique et éternel, sa figuration est à la fois âpre et créative. 
    <br><br>Selon qu’il attribue valeurs, proportions et volumes, son monde recomposé, destructuré, devient la traduction de son univers plastique équilibré et poétique.`,
    image: CauchiesLouis,
    miniature: CauchiesLouisMini,
    position: 7
  },
  {
    author: 'CAZACU Julia',
    title: 'Labyrinthe - Huile sur toile',
    description: `Toute représentation de la réalité est illusoire et donc abstraite. 
    <br><br>En observant l’œuvre de cette artiste, ses couleurs et ses rythmes, on peut ressentir que son auteur a voulu surtout représenter son intériorité, plutôt que la réalité. 
    <br><br>Son travail s’oriente vers une construction transparente de la couleur, jouant à la fois sur la vibration de la ligne, sensible au moindre mouvement psychique. 
    <br><br>La construction plate des surfaces, l’emboîtement des formes triangulaires définissent une atmosphère troublante qui vacille entre le souvenir et le rêve.`,
    image: CazacuJulia,
    miniature: CazacuJuliaMini,
    position: 8
  },
  {
    author: 'CIAVARELLA Marc',
    title: 'Lascive mystérieuse - Huile sur toile - 100 x 100 cm',
    description: `Le monde des rêves est souvent un exutoire au fantasme féminin, celui de cet artiste ne fait pas exception. 
    Les lascives mystérieuses peuplent ses toiles. 
    <br><br>On ne pourra qu’apprécier ce travail emprunt de poésie d’un artiste respectueux de son inspiration et qui n’a jamais tenté de forcer sa nature pour suivre une mode bourrée de caprices. 
    <br><br>Les passions que nous éprouvons dilatent les tissus, celles que nous réprimons les raccourcissent. 
    <br><br>Pour appréhender ses peintures, il nous faut les contempler avec l’âme et non avec les yeux.`,
    image: CiavarellaMarc,
    miniature: CiavarellaMarcMini,
    position: 9
  },
  {
    author: 'DELVAUX Annie',
    title: 'Cargo rouillé - Huile sur toile - 80 x 80 cm',
    description: `C’est le spectateur qui complète l’œuvre en la lisant dans son intelligibilité ou en se laissant porter par l’émotion de sa beauté, son besoin est d’être complice du résultat. 
    On peut situer ses peintures dans cet espace intermédiaire entre l’abstraction et la réalité. 
    <br><br>Cette artiste est une technicienne surprenante de la couleur et du rythme, amoureuse de la matière. 
    <br><br>Elle ne peint ni l’âme, ni la signification des choses. 
    <br><br>Elle a l’optimisme de celles qui se livrent aux forces de la nature et de l’instinct, une double sérénité naît en elle, de la joie qu’elle éprouve à la contempler et de la certitude qu’elle a d’égaler l’image restituée à l’image qu’elle reçoit.`,
    image: DelvauxAnnie,
    miniature: DelvauxAnnieMini,
    position: 10
  },
  {
    author: 'DEHTZ Jacqueline',
    title: 'Harras - Huile sur toile - 80 x 100 cm',
    description: `Un regard critique sur l’illusion d’objectivité de la réalité observée doit passer par le préalable d’une « prise de possession » des termes intégraux de cette réalité. 
    <br><br>La photo permet, non de se distancer du réel, mais d’en asservir la vision. 
    <br><br>L’œuvre de cette artiste reprend un thème classique, dans ses tableaux de chevaux, elle élabore les mêmes modèles dans des registres différents. 
    <br><br>La sûreté de son geste porte en lui un fort potentiel d’émotion, invitant à la rêverie, elle flatte l’œil à la fois par sa douceur, son expressivité et sa sensibilité.`,
    image: DehtzJacqueline,
    miniature: DehtzJacquelineMini,
    position: 11
  },
  {
    author: 'DUMORTIER Virginie',
    title: 'Couple - Acrylique sur toile - 80 x 80 cm',
    description: `Ses œuvres mises en abîme dégagent une lumière qui respire et étincelle. 
    Ce sont des rappels à soi-même, à sa propre mémoire et à ses ambiguïtés. 
    <br><br>L’artiste s’ingénie à préciser les infinies différences qui font d’une vie, d’un destin, quelque chose d’unique et cependant d’insaisissable. 
    <br><br>A bien regarder ses portraits, on comprend qu’ils n’existeraient pas ou disparaîtraient aux confins de l’amnésie sans le regard des autres.`,
    image: DumortierVirginie,
    miniature: DumortierVirginieMini,
    position: 12
  },
  {
    author: 'EVRARD Marcelle',
    title: 'Pink Floyd - Aquarelle sur toile - 50 x 60 cm',
    description: `Ses huiles et aquarelles sont attirantes par leurs contrastes chromatiques et surtout par leurs ambiances. 
    Elles frappent également au premier regard par la vibration lumineuse qui en émane, faisant osciller l’espace et la matière. 
    <br><br>C’est le type même d’œuvres dans lesquelles chaque visiteur puisera ce qu’il veut y trouver. 
    La discussion n’étant jamais aboutie, définitive, il en va ainsi de toute création corsée de poésie.`,
    image: EvrardMarcelle,
    miniature: EvrardMarcelleMini,
    position: 13
  },
  {
    author: 'FIEVEZ Béatrice',
    title: 'Iroquois - Techniques mixtes sur toile - 60 x 90 cm',
    description: `Après avoir observé et analysé la réalité, cette artiste traite le sujet franchement, tout en y intégrant consciemment sa propre atmosphère. 
    <br><br>Le contraste, que forme ce portrait buriné par les tourments et sa silhouette, s’est dissous au profit d’un jeu de lignes sinueuses et gracieuses. 
    <br><br>Elle sait percevoir et traduire la rugosité d’un visage, retranscrire le regard mystérieux d’un guerrier et rendre, avec élégance et sensibilité, la vision éphémère de beauté d’une culture agonisante ainsi que son désir de s’emparer de son identité, de le reconnaître dans son désespoir et dans sa sincérité.`,
    image: FievezBeatrice,
    miniature: FievezBeatriceMini,
    position: 14
  },
  {
    author: 'FOUQUEMBERG Dominique',
    title: 'Le pont de Brooklyn - Huile sur toile - 80 x 60 cm',
    description: `La photographie tient une place importante dans son procédé artistique, elle est une œuvre virtuelle prête à être revisitée. 
    <br><br>Ses paysages urbains éblouissants, d’une lumière quasi mystique, sont les reflets du divin, d’une artiste silencieuse. 
    <br><br>Le réalisme poétique est la substance même de toute création artistique, la lumière, l’obscurité, la tristesse étant les fondements de son œuvre. 
    Son regard sur l’humanité et sur son évolution est emprunt d’une réflexion pessimiste.`,
    image: FouquembergDominique,
    miniature: FouquembergDominiqueMini,
    position: 15
  },
  {
    author: 'GERARD Alain',
    title: 'Statue du Cinquantenaire - Techniques mixtes sur bois - 90 x 190 cm',
    description: `De par ses inspirations profondément ancrées dans le passé, sondant la mémoire du temps et des époques, son travail exprime avec force et authenticité son amour des maîtres anciens. 
    <br><br>Le fait de pénétrer dans les fondations de l’histoire dans ce qui lui donne sa raison d’être, lui permet de connaître l’effet de l’œuvre d’art sur l’âme humaine. 
    <br><br>La sérénité, révélée dans les plis du vêtement et dans le mouvement du tissu blanc s’accrochant au corps, est significative de sa recherche d’absolu.`,
    image: GerardAlain,
    miniature: GerardAlainMini,
    position: 16
  },
  {
    author: 'GERIN Séverin',
    title: 'Les ateliers du moulage - Huile sur toile - 100 x 100 cm',
    description: `L’idée de l’histoire et du passé qui hante sa démarche, renvoie certes à une forme de mélancolie. 
    Singulièrement pourtant, c’est lorsque l’homme est absent de l’œuvre, que l’artiste nous la rend encore plus bouleversante. 
    <br><br>L’association entre le visible et le suggéré laisse entendre que tous deux sont nécessaires, l’un pour percevoir le sujet, l’autre pour assumer le rythme. 
    <br><br>C’est le spectateur qui complète l’œuvre en la lisant dans sa complexité, tout en se laissant porter par l’émotion de sa beauté.`,
    image: GerinSeverin,
    miniature: GerinSeverinMini,
    position: 17
  },
  {
    author: 'HENQUIN Marie-Christine',
    title: 'Piscine de Roubaix - Techniques mixtes sur toile - 100 x 80 cm',
    description: `Son travail définit un monde saisi par un enchantement classique de l’ordre et de la quiétude. 
    <br><br>Cette artiste préfère retrouver dans les différents aspects de la réalité son unité profonde. 
    <br><br>Son œuvre n’est pas le résultat d’une vision directe, ni d’un souvenir immédiat, mais plutôt de rappels d’expériences oubliées depuis longtemps. 
    <br><br>C’est seulement lorsque les images visuelles sont matériellement évaporées et disparues dans son inconscient qu’elles sont prêtes à être revisitées et à naître en une interprétation personnelle.`,
    image: HenquinMarieChristine,
    miniature: HenquinMarieChristineMini,
    position: 18
  },
  {
    author: 'HONNAY Thérèse',
    title: 'Paysage chinois - Aquarelle - 33 x 47 cm',
    description: `Composition, couleur, lumière et ombre caractérisent l’œuvre de cette artiste. 
    <br><br>Ses représentations de paysages sont placées sous le signe des conditions de vie humaine en y accentuant la confrontation de la nature et de la civilisation. 
    <br><br>Singulièrement émue par la présence silencieuse des pêcheurs, jour après jour, de l’aurore au coucher de soleil. Son aquarelle est saisissante de beauté mystérieuse. 
    <br><br>La tension de la ligne, le rythme qui glisse sur les raccourcis, indiquent l’intérêt qu’elle porte au graphisme chinois.`,
    image: HonnayTherese,
    miniature: HonnayThereseMini,
    position: 19
  },
  {
    author: 'JUNG Dominique',
    title: 'Rayon de lune - Huile sur toile - 80 x 80 cm',
    description: `La constante dans l’œuvre de cette artiste est présente dans ses peintures récentes, elles semblent avoir un destin propre qui révèle un aspect plus secret. 
    On dirait que sa perception visuelle prend plus vivement conscience de soi, de ses qualités propres d’introspection. 
    <br><br>Elles sont souvent réduites à presque rien d’identifiable, un ciel, une plage, une présence humaine, tout cela sous forme d’atmosphère d’une densité émotionnelle envoûtante. 
    <br><br>L’œil est gagné par la fascination, il cesse par moment d’identifier les images, il va de teinte en teinte, de tracé en tracé.`,
    image: JungDominique,
    miniature: JungDominiqueMini,
    position: 20
  },
  {
    author: 'KOUSSIPETKOS Georgios',
    title: 'Attente - Techniques mixtes sur papier - 80 x 60 cm',
    description: `La figure humaine est la vraie protagoniste de l’œuvre de cet artiste. 
    L’émotion est dans l’intrigue suggérée, elle est aussi et surtout dans le questionnement de l’homme. 
    <br><br>On peut y ressentir l’ombre de la vie qui passe. 
    <br><br>Ici point de tapageuses couleurs, le peintre procède avec une sobriété constante, ses personnages sont dans l’attente de la révélation hypothétique du sens de la vie. 
    <br><br>Observateur attentif de ses semblables, il sait mettre en scène sa propre interrogation avec un toucher juste, riche et profond, donnant à son œuvre une magistrale consistance. 
    <br><br>Quelle audace dans son art opposant la densité des âmes à l’extrême économie de sa palette.`,
    image: KoussipetkosGeorgios,
    miniature: KoussipetkosGeorgiosMini,
    position: 21
  },
  {
    author: 'LANZONE Laura',
    title: 'Avenue - Huile sur toile - 60 x 90 cm',
    description: `Dans ses peintures légères mais convaincantes, cette artiste exprime les impressions ressenties au cours de ses voyages déjà vécus ou rêvés. 
    <br><br>En sondant son âme et ses souvenirs, elle nous livre une représentation imagée où s’épanouit sa créativité emprunte de lyrisme. 
    <br><br>La matérialité de son œuvre et la stabilité des formes naturelles génèrent un nouveau style de beauté contemporaine. 
    <br><br>En une seule vision, elle amalgame les lignes de force dues à la schématisation des formes urbaines et les interprétations linéaires surgies d’une inspiration momentanée de la lumière.`,
    image: LanzoneLaura,
    miniature: LanzoneLauraMini,
    position: 22
  },
  {
    author: 'LECLERCQ Agnès',
    title: 'Enfance - Huile sur zinc - 85 x 130 cm',
    description: `Ses sujets se prêtent parfaitement à l’utilisation harmonieuse de la matière naturelle de son support. 
    Sa représentation de l’enfance symbolise son aspiration à une vie paisible. 
    <br><br>Avec un certain mystère, elle atteint la perfection, ceci tant dans la composition que dans la pureté de ses couleurs. 
    <br><br>Malgré la simplicité, on dit que des événements souvent mineurs et tout à fait insignifiants au premier abord, sont susceptibles d’exercer une influence cruciale sur les âmes sensibles. 
    <br><br>La représentation intemporelle d’un instant revêtant un caractère universel sonde les profondeurs de l’existence.`,
    image: LeclercqAgnes,
    miniature: LeclercqAgnesMini,
    position: 23
  },
  {
    author: 'LIMELETTE Jean-François',
    title: 'Mons en musique - Huile sur papier - 98 x 157 cm',
    description: `L’utilisation du collage et de la matière utilisée à la préparation de ses fonds lui permet d’acquérir une gestuelle libre et expressive. 
    <br><br>On ne peut rester insensible devant son œuvre touchante, profondément humaine, témoignage simple et intimiste d’existences difficiles. 
    <br><br>Sans se river aux drames qui traversent le monde, il devient rapporteur de vérités plus profondes encore, en étant dramaturge d’existences qui nous ressemblent et nous rassemblent dans des halos de plus en plus incertains.`,
    image: LimeletteJeanFrancois,
    miniature: LimeletteJeanFrancoisMini,
    position: 24
  },
  {
    author: 'MARQUE Denis',
    title: 'Introspection - Techniques mixtes sur bois - 45 x 180 cm',
    description: `Les personnages peints par cet artiste sont sans visages. 
    L’individualisme et la communication ont inventé la solitude et l’anonymat. 
    <br><br>Ses personnages aux visages suggérés seraient-ils métaphore implacable, terrible de portraits de « nous-mêmes », êtres sans importance ? 
    <br><br>Nos souffrances endommagent lourdement notre perception de « qui nous sommes » ainsi que ceux qui nous entourent. 
    Or, abîmer le regard, c’est abîmer la conscience.
    <br><br>Le pouvoir de l’art et de notre propre démarche artistique est de nous éveiller à l’introspection.`,
    image: MarqueDenis,
    miniature: MarqueDenisMini,
    position: 25
  },
  {
    author: 'MINGRINO Attilio',
    title: 'Afrique - Huile sur toile - 100 x 100 cm',
    description: `La diversité des thèmes traités prouvent son attachement à l’homme, au monde animal et à son environnement. 
    <br><br>L’œuvre de cet artiste invite le visiteur à un dialogue enrichissant, tout en laissant suffisamment d’espace pour y projeter ses propres interrogations. 
    <br><br>Tout ceci forme la trame esthétique de son travail, jusque dans les moindres détails, grâce à sa maîtrise technique. 
    <br><br>L’intensité d’expression, malgré son dépouillement, ne rejoint cependant pas l’effroyable sentiment d’angoisse que suscite la confrontation de la nature et du monde moderne.`,
    image: MingrinoAttilio,
    miniature: MingrinoAttilioMini,
    position: 26
  },
  {
    author: 'MROWCZYNSKI Pierre',
    title: 'Politesse - Huile sur toile - 60 x 80 cm',
    description: `Parler de magie picturale ou d’ensorcellement par la couleur ne suffit pas si l’on n’est pas soi-même compromis et atteint par ce que l’on voit. 
    <br><br>Cette représentation de l’enfance, née de l’imagination de cet artiste a force de provocation. 
    Il arrive que ce caractère provocateur soit le fait d’une exaspération personnelle, reflet d’une forme de signification critique. 
    <br><br>Des graffitis vagabonds s’inscrivent sur des surfaces irrégulières sans respecter les limites, posant le principe que l’art doit être à la portée de tous.`,
    image: MrowczynskiPierre,
    miniature: MrowczynskiPierreMini,
    position: 27
  },
  {
    author: 'NOEL Dinah',
    title: 'Famille - Gravure et photo - 60 x 80 cm',
    description: `Sur son support, l’artiste a le don de créer un monde réel tout en relevant la magie de l’invisible. 
    <br><br>Ses gravures sont remplies de symboles, reflets d’une pensée trop profonde pour être autrement exprimée. 
    <br><br>Elle aime esquisser le mouvement, les émotions, la nostalgie derrière des mimiques à peine tracées, des regards qui s’évitent, une atmosphère particulière pour une création individualisée.
    Ses personnages sont imprégnés d’une éternelle solitude, les rendant plutôt discrets, ainsi par magie émerge une vie insolite qui charme nos sentiments.
    <br><br>Oeuvre monochrome, le gris et le noir ne signifient pas la tristesse ni la mélancolie, c’est la couleur locale … celle de son pays.`,
    image: NoelDinah,
    miniature: NoelDinahMini,
    position: 28
  },
  {
    author: 'PONTHIEU Marie-Christine',
    title: 'Mont Saint-Michel - Huile sur toile - 80 x 100 cm',
    description: `Cette artiste s’attache aux symboles élémentaires de la civilisation, en particulier à des sites prestigieux, à leur architecture et à leur insertion dans le paysage. 
    <br><br>Elle attire l’attention sur le monde de symboles particuliers de l’architecture religieuse. 
    <br><br>Ce système de symboles autonomes fait surgir un nouveau contexte, une deuxième vision. 
    <br><br>Son œuvre est soumise à la loi de la métamorphose, ne voulant pas fixer ce que le visiteur ressent. 
    C’est son point de vue sans préjugé mais passionné.`,
    image: PonthieuMarieChristine,
    miniature: PonthieuMarieChristineMini,
    position: 29
  },
  {
    author: 'RINCON GIL Mari-Pili',
    title: 'Arroz - Techniques mixtes - 80 x 100 cm',
    description: `Cette artiste désagrège et réinvite, les couches s’entassent : tissus, sable, acrylique, matériaux, huile, ...
    <br><br>Elle peint comme on sait peindre de nos jours, sans obligation, avec passion, dans l’emprise de la matière, consciente que tout a déjà été testé et exposé.
    L’espace peint est d’abord corporel, plutôt l’impact d’une émotion esthétique que jubilation intellectuelle. 
    <br><br>L’oeuvre exige une attention ouverte touchant tous les niveaux d’art et de conscience. 
    <br><br>Chaque élément intervient à part entière : couleur, matière, griffure, geste, dessins, ... les figures surgissent, pointant la vérité de celle ou de celui qui se livre.`,
    image: RinconGilMariPili,
    miniature: RinconGilMariPiliMini,
    position: 30
  },
  {
    author: 'ROSSI Anita',
    title: 'Lumière - Huile sur toile - 60 x 80 cm',
    description: `La peinture de cette artiste a ses moments, ses thèmes privilégiés. 
    <br><br>C’est une œuvre poétique dans sa clarté trouble et son évidence obscure, source multiple de sensations et d’interprétations. 
    <br><br>Ces toiles ne racontent pas, elles sont rigoureuses et prennent appui sur le visible, pour nous renvoyer des images qui parlent aux sens et à l’intelligence. 
    Dans le silence strident de la couleur et de la lumière mise en forme.`,
    image: RossiAnita,
    miniature: RossiAnitaMini,
    position: 31
  },
  {
    author: 'TELLIER Jacqueline',
    title: 'La lessive - Huile sur toile - 60 x 80 cm',
    description: `Même si elle est éminemment picturale, l’œuvre de cette artiste n’en est pas moins littéraire et philosophique. 
    <br><br>Elle raconte, organise des intrigues ambiguës et sous-entend une introspection sur le rôle du sujet et du regard. 
    C’est le gros risque des peintres narratifs de susciter des variations sur le récit et le texte, afin d’introduire des interprétations multiples et métaphysiques. 
    <br><br>La présence d’enfants dans son œuvre nous interpelle, ils nous épient, nous questionnent en attente d’une réponse qui ne viendra jamais.`,
    image: TellierJacqueline,
    miniature: TellierJacquelineMini,
    position: 32
  },
];

export default data
