import bakir1 from './../assets/images/charleville/f_emine_1.jpg'
import bakirMini1 from './../assets/images/charleville/m_emine_1.jpg'
import bakir2 from './../assets/images/charleville/f_emine_2.jpg'
import bakirMini2 from './../assets/images/charleville/m_emine_2.jpg'
import bakir3 from './../assets/images/charleville/f_emine_3.jpg'
import bakirMini3 from './../assets/images/charleville/m_emine_3.jpg'
import bakir4 from './../assets/images/charleville/f_emine_4.jpg'
import bakirMini4 from './../assets/images/charleville/m_emine_4.jpg'
import beugnies1 from './../assets/images/charleville/f_jeanpaul_1.jpg'
import beugniesMini1 from './../assets/images/charleville/m_jeanpaul_1.jpg'
import beugnies2 from './../assets/images/charleville/f_jeanpaul_2.jpg'
import beugniesMini2 from './../assets/images/charleville/m_jeanpaul_2.jpg'
import chavalle1 from './../assets/images/charleville/f_jade.jpg'
import chavalleMini1 from './../assets/images/charleville/m_jade.jpg'
import dumortier1 from './../assets/images/charleville/f_virginie.jpg'
import dumortierMini1 from './../assets/images/charleville/m_virginie.jpg'
import fievez1 from './../assets/images/charleville/f_beatrice.jpg'
import fievezMini1 from './../assets/images/charleville/m_beatrice.jpg'
import fourneaux1 from './../assets/images/charleville/f_nathalie.jpg'
import fourneauxMini1 from './../assets/images/charleville/m_nathalie.jpg'
import gerard1 from './../assets/images/charleville/f_alain.jpg'
import gerardMini1 from './../assets/images/charleville/m_alain.jpg'
import gerin1 from './../assets/images/charleville/f_severin.jpg'
import gerinMini1 from './../assets/images/charleville/m_severin.jpg'
import gillis1 from './../assets/images/charleville/f_veronique.jpg'
import gillisMini1 from './../assets/images/charleville/m_veronique.jpg'
import gobert1 from './../assets/images/charleville/f_andre.jpg'
import gobertMini1 from './../assets/images/charleville/m_andre.jpg'
import lanzone1 from './../assets/images/charleville/f_laura.jpg'
import lanzoneMini1 from './../assets/images/charleville/m_laura.jpg'
import lekeu1 from './../assets/images/charleville/f_chantal.jpg'
import lekeuMini1 from './../assets/images/charleville/m_chantal.jpg'
import marque1 from './../assets/images/charleville/f_denis_1.jpg'
import marqueMini1 from './../assets/images/charleville/m_denis_1.jpg'
import marque2 from './../assets/images/charleville/f_denis_2.jpg'
import marqueMini2 from './../assets/images/charleville/m_denis_2.jpg'
import tenret1 from './../assets/images/charleville/f_diane_1.jpg'
import tenretMini1 from './../assets/images/charleville/m_diane_1.jpg'
import tenret2 from './../assets/images/charleville/f_diane_2.jpg'
import tenretMini2 from './../assets/images/charleville/m_diane_2.jpg'
import tenret3 from './../assets/images/charleville/f_diane_3.jpg'
import tenretMini3 from './../assets/images/charleville/m_diane_3.jpg'
import tenret4 from './../assets/images/charleville/f_diane_4.jpg'
import tenretMini4 from './../assets/images/charleville/m_diane_4.jpg'

const data = [
    {
        author: 'BAKIR Emine',
        title: 'Regards sur le monde - Huile sur bois - 30 x 30 cm',
        description: ``,
        image: bakir1,
        miniature: bakirMini1,
        position: 1
    },
    {
        author: 'BAKIR Emine',
        title: 'Regards sur le monde - Huile sur bois - 30 x 30 cm',
        description: ``,
        image: bakir2,
        miniature: bakirMini2,
        position: 2
    },
    {
        author: 'BAKIR Emine',
        title: 'Regards sur le monde - Huile sur bois - 30 x 30 cm',
        description: ``,
        image: bakir3,
        miniature: bakirMini3,
        position: 3
    },
    {
        author: 'BAKIR Emine',
        title: 'Regards sur le monde - Huile sur bois - 30 x 30 cm',
        description: ``,
        image: bakir4,
        miniature: bakirMini4,
        position: 4
    },
    {
        author: 'BEUGNIES Jean-Paul',
        title: 'Samourai - Aquarelle et collages - 40 x 50 cm',
        description: ``,
        image: beugnies1,
        miniature: beugniesMini1,
        position: 5
    },
    {
        author: 'BEUGNIES Jean-Paul',
        title: 'Geisha - Aquarelle et collages - 40 x 50 cm',
        description: ``,
        image: beugnies2,
        miniature: beugniesMini2,
        position: 6
    },
    {
        author: 'CHAVALLE Jade',
        title: 'Marché aux puces - Aquarelle - 40 x 60 cm',
        description: ``,
        image: chavalle1,
        miniature: chavalleMini1,
        position: 7
    },
    {
        author: 'DUMORTIER Virginie',
        title: 'Lovée - Acrylique et huile sur métal - 60 x 80 cm',
        description: ``,
        image: dumortier1,
        miniature: dumortierMini1,
        position: 8
    },
    {
        author: 'FIEVEZ Béatrice',
        title: 'Peuples indigènes - Huile sur toile - 60 x 80 cm',
        description: ``,
        image: fievez1,
        miniature: fievezMini1,
        position: 9
    },
    {
        author: 'FOURNEAUX Nathalie',
        title: 'La légèreté de l\'être - Techniques mixtes et collage - 29 x 49 cm',
        description: ``,
        image: fourneaux1,
        miniature: fourneauxMini1,
        position: 10
    },
    {
        author: 'GERARD Alain',
        title: 'Portraits statues - Techniques mixtes - 60 x 120 cm',
        description: ``,
        image: gerard1,
        miniature: gerardMini1,
        position: 11
    },
    {
        author: 'GERIN Séverin',
        title: 'Atelier du modelage de Bruxelles - Huile sur toile - 100 x 100 cm',
        description: ``,
        image: gerin1,
        miniature: gerinMini1,
        position: 12
    },
    {
        author: 'GILLIS Véronique',
        title: 'Oiseau mouche - Huile sur papier sur bois - 45 x 75 cm',
        description: ``,
        image: gillis1,
        miniature: gillisMini1,
        position: 13
    },
    {
        author: 'GOBERT André',
        title: 'Enola - Huile sur zinc - 43 x 58 cm',
        description: ``,
        image: gobert1,
        miniature: gobertMini1,
        position: 14
    },
    {
        author: 'LANZONE Laura',
        title: 'Intrique - Acrylique sur toile - 60 x 90 cm',
        description: ``,
        image: lanzone1,
        miniature: lanzoneMini1,
        position: 15
    },
    {
        author: 'LEKEU Chantal',
        title: 'Vague à l\'âme - Huile sur toile - 50 x 60 cm',
        description: ``,
        image: lekeu1,
        miniature: lekeuMini1,
        position: 16
    },
    {
        author: 'MARQUE Denis',
        title: 'Le monde du silence - Acrylique et huile sur bois - 122 x 35 cm',
        description: ``,
        image: marque1,
        miniature: marqueMini1,
        position: 17
    },
    {
        author: 'MARQUE Denis',
        title: 'Le monde du silence - Acrylique et huile sur bois - 122 x 35 cm',
        description: ``,
        image: marque2,
        miniature: marqueMini2,
        position: 18
    },
    {
        author: 'TENRET Diane',
        title: 'Esprit zen - Engobe sur grés - 42 cm',
        description: ``,
        image: tenret1,
        miniature: tenretMini1,
        position: 19
    },
    {
        author: 'TENRET Diane',
        title: 'Naissance florale - Porcelaine coulée à la cuillère - 23 x 13 x 8 cm',
        description: ``,
        image: tenret2,
        miniature: tenretMini2,
        position: 20
    },
    {
        author: 'TENRET Diane',
        title: 'Prison de l\'âme - Porcelaine et métal - 20 x 20 cm',
        description: ``,
        image: tenret3,
        miniature: tenretMini3,
        position: 21
    },
    {
        author: 'TENRET Diane',
        title: 'Manae - Porcelaine papier - 18 x 17.5 x 12 cm',
        description: ``,
        image: tenret4,
        miniature: tenretMini4,
        position: 22
    },
];

export default data